<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <b-card-body class="p-0">
          <b-table
            striped
            hover
            responsive
            class="position-relative"
            :per-page="perPage"
            :current-page="currentPage"
            :items="history_list"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            :filter-included-fields="filterOn"
            @filtered="onFiltered"
          >
            <template #cell(action)="data">

              <b-button
                  class="mt-1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  style="
                    position: relative;
                    padding: 8px;
                    width: 31px;
                    margin-top: 0px !important;
                  "
                  :to="{
                    name: 'clock-history-edit',
                    params: { id: data.item.id },
                  }"
                >
                  <feather-icon icon="EditIcon" class="mr-50" title="Edit" />
                </b-button>

            </template>
          </b-table>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>
  
  <script>
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BCardBody,
  BTable,
  BDropdownItem,
  BDropdown,
} from "bootstrap-vue";
import { getUserData } from "@/auth/utils";
import axios from "axios";
import { serverUri } from "@/config";
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BCard,
    BCardBody,
    BTable,
    BDropdownItem,
    BDropdown,
  },
  data() {
    return {
      drivers: [],
      perPage: 25,
      pageOptions: [5, 10, 15, 25],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: " ",
      history_list: [],
      filterOn: [],
      fields: [
        {
          key: "date",
          label: "Date",
          formatter: (value) => {
            return moment(value).format("MM/DD/YYYY");
          },
        },
        {
          key: "clock_in",
          label: "Clock IN",
          sortable: false,
          formatter: (value) => {
            return moment(value).format("HH:mm:ss");
          },
        },
        {
          key: "date",
          label: "Date",
          sortable: false,
          formatter: (value) => {
            return moment(value).format("MM/DD/YYYY");
          },
        },
        {
          key: "date_out",
          label: "Date",
          sortable: false,
          formatter: (value) => {
            return moment(value).format("MM/DD/YYYY");
          },
        },
        {
          key: "clock_out",
          label: "Clock Out",
          sortable: false,
          formatter: (value) => {
            return moment(value).format("HH:mm:ss");
          },
        },
        
        {
          key: "total_time",
          label: "Hours Worked",
          sortable: false,
          formatter: (value) => {
            const hours = Math.floor(value / 3600);
            const minutes = Math.floor((value % 3600) / 60);
            return `${hours} hours and ${minutes} minutes`;
          },
        },
        {
          key: "action",
        },
      ],
      userData: getUserData(),
      userId: getUserData().id,
      companyId: getUserData().company_id,
      driverName: getUserData().username.toUpperCase(),
      companyName: getUserData().company_name.toUpperCase(),
    };
  },

  computed: {
    // ...mapState({
    //   items: (state) => this.history_list,
    // }),
    filteredItems() {
      let filteredItems = this.history_list.filter((item) => {
        return item.driver_name
          .toLowerCase()
          .includes(this.searchQuery.toLowerCase());
      });
      let orderedItems = filteredItems.sort((a, b) => {
        return b.upvoted - a.upvoted;
      });
      return orderedItems;
    },
    // formattedTime(total_time) {
    // 	const hours = Math.floor(total_time / 3600);
    // 	const minutes = Math.floor((total_time % 3600) / 60);
    // 	return `${hours} hours ${minutes} minutes`;
    // }
  },
  mounted() {
    this.getClockHistory();
  },
  created() {
    // data
  },
  methods: {
    getClockHistory() {
      this.$http
        .post(`${serverUri}/driver/clock-history`, {
          driver_id: this.userId,
          company_id:this.companyId
        })
        .then((response) => {
          this.data = response.data;
          if (response.data.status == true) {
            this.history_list = response.data.data;
          } else {
            this.history_list = [];
          }
        });
    },

    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
  